<template>
  <div>
    <section class="main services">
      <div class="hero parallax"></div>

      <h2>{{ currentRouteName }}</h2>

      <article>
        <div class="thumb"></div>
        <p class="intro">
          GammaTeam Security provides <router-link to="/services">security services</router-link> for healthcare establishments and institutions, such as hospitals, clinics, healthcare centers, medical homes, long-term care facilities, nursing home care establishments, doctor's offices, etc.<br /><br />

          Based on the recent crime data reports generated by International Association for Healthcare Security and Safety (IAHSS), the overall violent crime rate at healthcare facilities has increased in 2018 compared to previous years. To change the situation and make a difference, GammaTeam Security works for our clients to get them back a piece of mind and smooth internal and external operations throughout day and night at their healthcare facilities.<br /><br />

          Our team of professionally trained security guards insures a desired atmosphere of safety for the staff, patients, and visitors. Working 24/7, our armed and unarmed security guards monitor the environment and detect suspicious behavior if such occurs. They protect the clients' property, staff and visitors as well as medicine prescriptions, storages, keeping any and all medical information 100% confidential.<br /><br />

          We understand each facility requires a different level of security depending on various factors. This is why our GTS team always provides our potential and existing clients with detailed security assessments to define specific needs in security at our clients' place of business.
        </p>
      </article>

      <call-to-action :link_back=true></call-to-action>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HealthcareSecurity'
}
</script>

<style scoped>
section.main article div.thumb {
  background-image: url("/images/services/hospital.jpg");
}
</style>
